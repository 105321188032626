import {IonToolbar} from '@ionic/react';
import styled from "styled-components";

export const FooterIonToolbar = styled(IonToolbar)`
  min-height: 150px;
  display: flex;
  --background: var(--ion-color-primary);
  color: var(--ion-color-secondary);
  ion-grid, ion-row{
    height: 150px;
  }
  ion-col{
    display: flex;
    .footer-content{
      flex:1 1 auto;
      align-self: center;
      justify-self: center;
      text-align: center;
    }
  }
`;
