import { IonCol, IonRow, IonButton } from "@ionic/react";
import React, { useState } from "react";
import { NavCol, NavButton } from "./NavigationStyles";

interface NavigationType {
  activeLink: string;
}

interface NavLinkItemType {
  name: string;
  path: string;
  icon?: string;
}

const Navigation: React.FC<NavigationType> = ({ activeLink }) => {
  const navLinks: NavLinkItemType[] = [
    {
      name: "Home",
      path: "/home",
      icon: ""
    },
    {
      name: "About",
      path: "/about",
      icon: ""
    },
    {
      name: "Contact",
      path: "/contact",
      icon: ""
    }
  ];

  return (
    <IonRow>
      {navLinks.map((navLink: any) => (
        <NavCol key={navLink.name}>
          <NavButton
            expand="full"
            color={activeLink == navLink.path ? "secondary" : "primary"}
            routerLink={navLink.path}
            routerDirection="none"
          >
            {navLink.name}
          </NavButton>
        </NavCol>
      ))}
    </IonRow>
  );
};

export default Navigation;
