import { IonPage } from "@ionic/react";
import React from "react";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { MainIonContent } from "./HomeStyles";

interface HomeType {
  navLinks: [];
}

const Home: React.FC<HomeType> = () => {
  return (
    <IonPage>
      <Header page="home" />
      <MainIonContent className="ion-padding">
        <h2>Under Construction</h2>
      </MainIonContent>
      <Footer />
    </IonPage>
  );
};

export default Home;
