import { IonContent, IonPage } from "@ionic/react";
import React from "react";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
// import { MainIonContent } from "./ContactStyles";
interface ContactType {}

const Contact: React.FC<ContactType> = () => {
  return (
    <IonPage>
      <Header page="contact" />
      <IonContent className="ion-padding">
        <h2>Under Construction</h2>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default Contact;
