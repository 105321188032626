import { IonContent, IonPage } from "@ionic/react";
import React from "react";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
// import { MainIonContent } from "./AboutStyles";
interface AboutType {}

const About: React.FC<AboutType> = () => {
  return (
    <IonPage>
      <Header page="about" />
      <IonContent className="ion-padding">
        <h2>Under Construction</h2>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default About;
