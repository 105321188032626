import { IonToolbar, IonCol } from "@ionic/react";
import styled from "styled-components";

export const HeaderIonToolbar = styled(IonToolbar)`
  --background: var(--ion-color-primary);
  display: flex;
  ion-title {
    padding: 0px;
  }
  h1 {
    margin: 0px;
    padding: 10px;
    flex: 1 1 auto;
    text-align: center;
    font-size: 4rem;
    font-weight: 900;
    background: var(--ion-color-light);
    color: var(--ion-color-primary);
    justify-content: center;
    ion-icon {
      font-size: 8rem;
      vertical-align: middle;
    }
  }
  ion-grid {
    height: 50px;
    ion-row {
      height: 100%;
    }
  }
  ion-col {
  }
`;

export const CenterIonCol = styled(IonCol)`
  background: var(--ion-color-secondary);
`;

export const NavCol = styled(IonCol)`
  padding: 0px 5px;
`;
