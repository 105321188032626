import {  IonFooter, IonGrid, IonRow, IonCol } from '@ionic/react';
import React from 'react';
import styled from "styled-components";
import { FooterIonToolbar } from "./FooterStyles";


const IonColCenter = styled(IonCol)`
  background: var(--ion-color-secondary);
`;

const Footer: React.FC = () => {
  return (
      <IonFooter>
        <FooterIonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="footer-content">
                  &copy; 2019
                </div>
              </IonCol>
              <IonColCenter></IonColCenter>
              <IonCol></IonCol>
            </IonRow>
          </IonGrid>
        </FooterIonToolbar>
      </IonFooter>
  );
};

export default Footer;
