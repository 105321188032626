import {
  IonHeader,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon
} from "@ionic/react";
import React from "react";
import { HeaderIonToolbar, CenterIonCol, NavCol } from "./HeaderStyles";
import Navigation from "../Navigation/Navigation";

interface HeaderType {
  page: string;
}

const Header: React.FC<HeaderType> = ({ page }) => {
  return (
    <IonHeader>
      <HeaderIonToolbar>
        <IonGrid>
          <IonRow>
            <IonCol></IonCol>
            <CenterIonCol></CenterIonCol>
            <NavCol>
              <Navigation activeLink={`/${page}`} />
            </NavCol>
          </IonRow>
        </IonGrid>
        <IonTitle>
          <h1>
            dev<IonIcon src={"/assets/icon/dev-xr-icon.svg"}></IonIcon>
          </h1>
        </IonTitle>
        <IonGrid>
          <IonRow>
            <IonCol></IonCol>
            <CenterIonCol></CenterIonCol>
            <IonCol></IonCol>
          </IonRow>
        </IonGrid>
      </HeaderIonToolbar>
    </IonHeader>
  );
};

export default Header;
